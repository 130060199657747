<script>
import { uploadFile } from "@/api/upload";
export default {
  name: "FileUploadSettings",
  data() {
    return {
      isVisible: true,
    };
  },
  methods: {
    async upload(params) {
      const formData = new FormData();
      formData.append("file", params.file);
      formData.append("type", "file");

      try {
        const data = await uploadFile(formData);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
    close() {
      this.$router.push({ name: "settings" });
    },
  },
};
</script>

<template>
  <el-drawer
    size="600"
    title="Загрузить файл"
    v-model="isVisible"
    @close="close"
  >
    <div class="h-full overflow-y-auto flex flex-col gap-y-2.5">
      <div>
        <el-upload
          drag
          :data="{ type: 'file' }"
          :show-file-list="true"
          :http-request="upload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            <em>Нажмите, чтобы загрузить</em>
          </div>
        </el-upload>
      </div>
    </div>
  </el-drawer>
</template>

<style scoped lang="scss"></style>
